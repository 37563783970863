body {
  margin: 0;
  font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5rem;
}

#root {
  position:absolute;
  top:0;right:0;bottom:0;left:0;
  height: 100%;
  text-align: center;
  background-color: #fff;
  padding: 0;
}

#root .page {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full-height {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.full-height>div {

}

.page {
  height: 100%;
  margin:0;
  padding:0;
}

/* BUTTONS */

#root .page .btn {
  padding: 1rem 2rem;
  cursor: pointer;
  margin: .5rem;
}

#root .page .btn.btn-primary {
  background-color: #fff;
  border:1px solid #3f1b5b;
  color: #3f1b5b;
}

#root .page .btn.btn-primary:hover{
  background-color: #eee;
  color: #3f1b5b;
}

#root .page .btn.btn-secondary {
  background-color: #3f1b5b;
  color: #fff;
  border:1px solid #3f1b5b;
}

#root .page .btn.btn-secondary:hover{
  background-color: #fff;
  color:#3f1b5b;
}

#root .page .btn:hover {
  box-shadow: 0 0 5px #ccc;
}

#root .page .btn:active{
  box-shadow:none;
}