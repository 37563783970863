@font-face {
    font-family: "Perisphere";
    src: url("/public/perisphere.woff");
}

.page {
    background-color: #fff;
    padding: 0;
    color: #3f1b5b;
}

.page .image-attrib {
    background-color: #eee;
    padding: 1rem;
    text-align: center;
    margin:0;
    width: unset;
    font-size: 1.2rem;
}

.home .hero {
    text-align: center;
}

.home>div {
    width: 100%;
    margin:0;
}

h1 {
    font-weight: 200;
}

.home .landing {
    flex-direction: column;
    justify-content:center;
    background-image: url(../../public/images/landing_1920.jpeg);
    background-position: center center;
    background-size: cover;
    background-color: #a6abb3;
}

.home .hero h1 {
    font-size: 7rem;
    margin-bottom: 0;
}

.home .hero{
    background-color: #3f1b5b; 
    background-image: url(../../public/images/home-hero-1920.jpg);
    background-position: center center;
    background-size: cover;
    min-height: 360px;
    color: #3f1b5b;
}

.home .brand {
    padding-top: 2rem;
    margin-bottom: 0;
}

.home .brand img {
    min-width: 40%;
    max-width: 900px;
}

.home .content {
    height: 100%;
    width: 100%;
    margin: 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    align-items: center;
}

.home .content div {
    width: 100%;
}

.home .slogan{
    margin-bottom: 1rem;
    padding: .5rem 0;
    font-size: 1.5rem;
    background: rgba(63,27,91,0.75);
    color: #fff;
    font-weight: 200;
}

.home .content h3 {
    padding-bottom: 1rem;
}

.home .focus-content {
    background-image: url(../images/focusbg.webp);
}

.home .content div.services {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.home .services button {
    flex-grow: 1;
    vertical-align: middle;
}

.home .services button svg {
    vertical-align:middle;
}

.home button {
    font-size: 1.2rem;
}